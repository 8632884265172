:root {
  --screen-pc: 1024px;
  --screen-mobile: 600px;
}

svg {
  vertical-align: unset;
}

.dropdown-menu.show {
  max-width: 10rem;
}

.full-width {
  width: 100%;
}

.link small {
  font-weight: 700;
}

.link small:hover {
  transition: 0.4s;
  color: var(--blue);
}

.obc-btn {
  width: 180px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2rem;
  display: flex;
  background-image: linear-gradient(
    rgb(72, 78, 85),
    rgb(58, 63, 68) 60%,
    rgb(49, 53, 57)
  );
  border-top: 1px solid rgba(0, 0, 0, 0.6);
  color: var(--light);
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  z-index: 1000;
}

.navbar {
  z-index: 1000;
}

.nav-item {
  cursor: pointer;
}

.navbar .nav-item.active .nav-link {
  background-color: rgba(0, 0, 0, 0.8);
}

.navbar-collapse .contact-user-group {
  margin-right: 16px;
  color: var(--secondary);
}

.contact-user-group i:hover {
  color: var(--light);
}

.navbar-collapse.collapsing .contact-user-group,
.navbar-collapse.show .contact-user-group {
  padding: 16px 0px;
  margin-left: 0px;
  margin-right: auto;
}

.alert-card {
  width: 25rem;
  max-width: 25rem;
}

.login-card {
  width: 20rem;
  max-width: 20rem;
  position: absolute;
  top: -100%;
  right: 1.5rem;
  z-index: 1001;
  transition: top 0.8s;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  color: var(--light);
}

.login-card .close {
  position: absolute;
  right: 4px;
  top: -4px;
  color: var(--light);
}

.forgot-password-card {
  z-index: 1003;
}

.change-user-card {
  z-index: 1002;
  top: -380px;
}

.rolled-out {
  top: 56px;
}

.outmost-card {
  max-width: 40rem;
  min-width: 12rem;
}

.pointer-cursor {
  cursor: pointer;
}

.activity {
  color: #808b96;
  display: inline;
}

.no-scroll {
  overflow-x: hidden;
}

.tool-tip {
  background: #333;
  color: white;
}

.tt-mediumwide {
  max-width: 18rem;
}

#obc-user,
select,
.form-check-label,
.form-check-input {
  cursor: pointer;
  transition: 0.4s;
}

#obc-user.logged-in:hover,
#obc-user:hover {
  color: var(--light);
}

#obc-user.logged-in {
  color: var(--success);
}

.obc-tooltip {
  opacity: 0;
  transition: opacity 0.4s ease-out;
  position: absolute;
  width: 20rem;
  padding: 4px 8px 8px;
  font-size: smaller;
  text-align: left;
  border: 2px solid rgba(80, 80, 80, 0.8);
  background: rgba(0, 0, 0, 0.9);
  color: rgba(211, 211, 211, 0.9);
  box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  pointer-events: none;
  z-index: 4;
}

.obc-tooltip .v-align {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* i.success {
  color: var(--green);
} */

.offence-tooltip-table {
  display: flex;
  min-width: 290px;
}

.tooltip-column {
  width: 50%;
  padding-left: 8px;
}

.tooltip-column:first-child {
  padding-left: 0px;
}

.export-card {
  width: 100px;
  /* height: 46px; */
}

.nowrap-row {
  white-space: nowrap;
}

.page-input {
  transition: 0.4s;
  min-height: 30px;
  width: 200px;
  z-index: 1;
  max-height: 30px;
}

.page-item {
  cursor: pointer;
}

.page-item.disabled {
  cursor: not-allowed;
}

.page-link {
  transition: 0.8s;
  height: 100%;
}

.page-link-icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: var(--light);
}

.btn.disabled {
  cursor: not-allowed;
}

.page-input::placeholder {
  color: white;
  opacity: 1;
  z-index: 1;
}

.small-screen {
  justify-content: space-evenly;
}

#myHeader {
  z-index: 200;
  width: calc(100% - 30px);
  position: absolute;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}

#myHeader.sticky {
  position: fixed;
  width: calc(100% - 30px);
}

.table-header {
  position: relative;
  padding: 0.25rem 0.5rem;
  font-size: 0.8203125rem;
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  color: #fff;
  background-color: #7a8288;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  border: 1px solid;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#8a9196),
    color-stop(60%, #7a8288),
    to(#70787d)
  );
  background-image: linear-gradient(#8a9196, #7a8288 60%, #70787d);
  background-repeat: no-repeat;
  -webkit-filter: none;
  filter: none;
  border-color: rgba(0, 0, 0, 0.6);
  transition: 0.8s;
}

.sticky {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 208;
}

#placeHolder {
  height: 22px;
  width: 100%;
  display: block;
}

.female-color,
.male-color,
.offence,
.obc-link,
.arrow-up {
  transition: 0.4s;
  stroke: var(--secondary);
  fill: none;
}

.female-color:hover {
  fill: #8e44ad;
  stroke: var(--light);
}

.male-color:hover {
  fill: var(--info);
  stroke: var(--light);
}

.offence:hover,
.obc-link:hover,
.arrow-up:hover {
  stroke: var(--light);
}

.arrow-up:hover {
  fill: var(--light);
}

.scribe {
  transition: 0.4s;
  fill: var(--secondary);
}

.scribe:hover {
  fill: var(--light);
}

.transparent-color {
  color: rgba(0, 0, 0, 0);
}

.search-card {
  width: 300px;
}

.outmost-card .card-body {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.context-col {
  min-width: 150px;
}

.pagination-card {
  padding: 4px;
}

.pagination-items {
  margin: 0px;
}

.pagination {
  margin: 0px;
}

.dropdown-menu {
  min-width: 300px;
}

.content {
  position: relative;
  margin-bottom: 3rem;
}

.key-content {
  text-align: center;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.hit-content-left {
  color: #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  padding-right: 0px;
  padding-left: 0px;
  text-align: right;
  cursor: pointer;
}

.inner-div {
  float: right;
  text-overflow: ellipsis;
}

.hit-content-right {
  color: #ddd;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  margin-right: 10px;
  cursor: pointer;
}

.part-content {
  white-space: pre;
  overflow: hidden;
  padding-left: 30px;
}

.full-content {
  color: #ddd;
  cursor: pointer;
}

.obc-progress {
  height: 0.2rem;
}

.gen-tag-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.url-col {
  width: 14px;
}

.year-col {
  width: 40px;
}

.gender-col {
  width: 10px;
}

.role-col {
  width: 60px;
}

.offence-col {
  width: 30px;
}

.class-col {
  width: 24px;
}

.hisco-col {
  width: 70px;
}

.scribe-col {
  width: 24px;
}

.role-badge {
  width: 59px;
}

.badge {
  padding: 0.25em 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-light,
.badge-success,
.badge-warning {
  transition: 0.4s;
  justify-content: center;
  background-color: unset;
  color: var(--secondary);
  border: 0.5px solid var(--secondary);
}

.badge-light:hover {
  background-color: var(--light);
  color: var(--dark);
  border-color: var(--light);
}

.badge-success:hover {
  background-color: var(--success);
  color: var(--dark);
  border-color: var(--success);
}

.badge-warning:hover {
  background-color: var(--warning);
  color: var(--dark);
  border-color: var(--warning);
}

.role-text {
  width: 45px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: '';
}

.social-class-badge {
  vertical-align: middle;
  cursor: pointer;
}

.obc-tag {
  display: flex;
  align-items: center;
  cursor: default;
}

.hisco-badge {
  width: 69px;
  cursor: pointer;
}

.hisco-text {
  width: 55px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: '';
}

.col-number {
  min-width: 40px;
}

.obc-row {
  transition: 0.8s;
  position: relative;
  /* max-height: 23px; */
}

.overflow {
  overflow: visible;
}

.no-overflow {
  overflow: hidden;
}

.expanded-text {
  position: relative;
  z-index: 1;
  /* top: 23px; */
  /* left: 40px; */
  left: -15px;
  padding: 0rem 0.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0);
  /* padding: 0.25rem 1rem; */
  /* background-color: var(--primary); */
  /* border: 1px solid var(--secondary); */
  /* border-bottom-left-radius: 2px; */
  /* border-bottom-right-radius: 2px; */
  /* border-color: rgba(0, 0, 0, 0); */
  /* border-radius: 2px; */
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 1); */
  transition: 0.8s;
  text-align: justify;
  /* opacity: 0; */
  max-height: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
  display: block;
  margin-bottom: 0px;
  /* visibility: hidden; */
  /* overflow: hidden; */
  /* max-height: fit-content; */
  /* box-sizing: border-box; */
  /* margin-bottom: 24px; */
}

.tag-col {
  -ms-flex: 0 0 272px;
  flex: 0 0 272px;
  flex-wrap: nowrap;
  overflow: hidden;
  transition: 0.8s;
  max-width: 272px;
}

.tag-col.collapsed {
  max-width: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.tag-row {
  flex-wrap: nowrap;
}

.tags-slider {
  position: absolute;
  transition: 0.8s;
  left: calc(100% + 50px);
  top: 0px;
  padding: 0px 30px;
  box-sizing: border-box;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--secondary);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 1);
  z-index: 3;
}

.tags-slider.open {
  left: calc(100% - 273px);
}

.tags-slider .tag-col {
  flex: 0 0 287px;
  flex-wrap: nowrap;
  overflow: hidden;
  transition: 0.8s;
  max-width: 287px;
  margin-left: -15px;
}

.tags-slider .tag-row {
  padding-left: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0);
}

/* ------------------------------------------------------------------------------------- */
/* When screen is narrow, the header of the tag-col is minimzed and dimmed
An Arrow is shown to expand/collapse the tags-slider */
.narrow-tag-menu-col {
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
  /* position: relative; */
  transition: 0.8s;
  cursor: pointer;
}

.narrow-tag-menu-col .table-header:not(.open),
.narrow-tag-menu-col .table-header:hover {
  filter: brightness(0.5);
}

.narrow-tag-menu-col .table-header:not(.open):hover {
  filter: brightness(1);
}

#tag-menu-arrow {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 7px;
  rotate: 90deg;
  transform: rotate3d(1, 0, 0, 0deg);
  transition: 0.8s;
}

#tag-menu-arrow.rotated,
.arrow-up.rotated {
  transform: rotate3d(1, 0, 0, 180deg);
}
/* ------------------------------------------------------------------------------------- */
